import React, { useEffect, useState } from 'react';
import { Table, TableProps, Select, Row, Col, Button, PaginationProps } from 'antd';
import { Link } from 'react-router-dom';
import { getRequest, ResponseDataType, postRequest } from 'api';
import { toast } from 'react-toastify';

const { Option } = Select;

interface unPaidBillDataType {
  invoidId: string;
  studentInvoiceId: number;
  studentId: number;
  studentName: string;
  className: string;
  createdDate: number;
  parentPhonenum: string;
  cost: number;
  content: string;
  description: string;
  status: number;
  paymentDate: string | null;
  updatedDate: number;
  schoolYear: string;
  month: string;
  year: string;
  studentCode: string;
}

interface NotificationDataType {
  status: number;
}

const formatCurrency = (value: number): string => {
  const formattedValue = new Intl.NumberFormat('vi-VN').format(value);
  return `${formattedValue} VNĐ`;
};

const formatCombinedData = (month: string, year: string, schoolYear: string): string => {
  const formattedMonth = month.padStart(2, '0');
  return `T${formattedMonth}-${year}-${schoolYear}`;
};

const columns: TableProps<unPaidBillDataType>['columns'] = [
  { title: 'Mã biên lai', dataIndex: 'invoidId', key: 'invoidId', align: 'center' },
  { title: 'Code', dataIndex: 'studentCode', key: 'studentCode', align: 'center' },
  { title: 'Tên học sinh', dataIndex: 'studentName', key: 'studentName', align: 'center' },
  { title: 'Lớp', dataIndex: 'className', key: 'className', align: 'center' },
  {
    title: 'Thời gian',
    dataIndex: 'createdDate',
    key: 'createdDate',
    render: (text: any, record: unPaidBillDataType) =>
      formatCombinedData(record.month, record.year, record.schoolYear),
    align: 'center',
  },
  { title: 'Phụ huynh', dataIndex: 'parentPhonenum', key: 'parentPhonenum', align: 'center' },
  {
    title: 'Số tiền',
    dataIndex: 'cost',
    key: 'cost',
    render: (cost: number) => formatCurrency(cost),
    align: 'center'
  },
  { title: 'Nội dung', dataIndex: 'content', key: 'content', align: 'center' },
  { title: 'Ghi chú', dataIndex: 'description', key: 'description', align: 'center' },
  {
    title: '',
    dataIndex: 'studentInvoiceId',
    key: 'action',
    render: (studentInvoiceId) => (
      <Link to={`/bill-list/bill-info/${studentInvoiceId}`}>Chi tiết</Link>
    ),
    align: 'center',
  },
];

export const PaidBillList = () => {
  const [unPaidBillList, setUnPaidBillList] = useState<unPaidBillDataType[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth() + 1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalItems, setTotalItems] = useState<number>(0);

  const years = [2021, 2022, 2023, 2024];
  const months = [
    { label: 'Tháng 1', value: 1 },
    { label: 'Tháng 2', value: 2 },
    { label: 'Tháng 3', value: 3 },
    { label: 'Tháng 4', value: 4 },
    { label: 'Tháng 5', value: 5 },
    { label: 'Tháng 6', value: 6 },
    { label: 'Tháng 7', value: 7 },
    { label: 'Tháng 8', value: 8 },
    { label: 'Tháng 9', value: 9 },
    { label: 'Tháng 10', value: 10 },
    { label: 'Tháng 11', value: 11 },
    { label: 'Tháng 12', value: 12 },
  ];
  const schoolId = localStorage.getItem('schoolId');

  const fetchUnpaidBills = async () => {
    const queryParams = new URLSearchParams({
      page: currentPage.toString(),
      perPage: pageSize.toString(),
      year: selectedYear.toString(),
      month: selectedMonth.toString().padStart(2, '0'),
      schoolId: schoolId || '',
      status: '1',
    });

    const url = `/cms/invoice/list-invoices?${queryParams.toString()}`;
    const response: ResponseDataType<{ list: unPaidBillDataType[], total: number }> = await getRequest(url);

    if (response?.code === 200) {
      setUnPaidBillList(response.info?.list || []);
      setTotalItems(response.info?.total || 0);
    } else {
      setUnPaidBillList([]);
      console.log(response?.msg || 'Lỗi không xác định');
    }
  };

  useEffect(() => {
    fetchUnpaidBills();
  }, [selectedYear, selectedMonth, currentPage, pageSize]);

  const handlePageChange: PaginationProps['onChange'] = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize || 10);
  };

  // const handleNotifyClick = async () => {
  //   const schoolidNumber = Number(localStorage.getItem('schoolId'));
  //   const formData = {
  //     title: 'Thông báo',
  //     message: 'Thông báo học phí',
  //     schoolId: schoolidNumber,
  //   };
  //   const response: ResponseDataType<NotificationDataType> = await postRequest(
  //     '/cms/invoice/send-notification-invoice',
  //     formData,
  //     'json'
  //   );

  //   if (response?.code === 200) {
  //     toast.success('Gửi thông báo thành công!');
  //   } else {
  //     toast.error('Gửi thông báo thất bại!');
  //   }
  // };

  return (
    <div>
      <Row gutter={8} style={{ marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
        <Col>
          <Select
            placeholder="Năm"
            value={selectedYear}
            onChange={(value) => setSelectedYear(value)}
            style={{ width: 120, marginRight: 8 }}
          >
            {years.map((year) => (
              <Option key={year} value={year}>
                {year}
              </Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Select
            placeholder="Tháng"
            value={selectedMonth}
            onChange={(value) => setSelectedMonth(value)}
            style={{ width: 120 }}
          >
            {months.map((month) => (
              <Option key={month.value} value={month.value}>
                {month.label}
              </Option>
            ))}
          </Select>
        </Col>
        {/* <Col span={18} style={{ textAlign: 'right' }}>
          <Button type="primary" onClick={handleNotifyClick}>
            Gửi thông báo
          </Button>
        </Col> */}
      </Row>
      <Table
        columns={columns}
        dataSource={unPaidBillList}
        rowKey="studentInvoiceId"
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalItems,
          pageSizeOptions: ['2', '10', '20', '50'],
          showSizeChanger: true,
          onChange: handlePageChange,
        }}
      />
    </div>
  );
};
