import { useEffect, useState } from 'react';
import { Table, TableProps } from 'antd';
import { Link } from 'react-router-dom';
import { getRequest, ResponseDataType } from 'api';

interface BillErrorDataType {
  id: string;
  description: string;
  row: string;
  type: number;
  createdDate: number;
  mstSchool: string;
}

interface ResponseInfoType {
  list: BillErrorDataType[];
  total: number; // Added total to handle total number of items
}

const columns: TableProps<BillErrorDataType>['columns'] = [
  {
    title: 'Mô tả lỗi',
    dataIndex: 'description',
    key: 'description',
    width: '300px',
  },
  {
    title: 'Dòng lỗi',
    dataIndex: 'row',
    key: 'row',
    width: '100px',
  },
  {
    title: 'Loại lỗi',
    dataIndex: 'type',
    key: 'type',
    width: '100px',
    render: (type) => {
      switch (type) {
        case 1:
          return 'Lặp mã hoá đơn';
        case 2:
          return 'Không có mã học sinh';
        default:
          return 'Không xác định';
      }
    },
  },
  {
    title: 'Thời điểm lỗi',
    dataIndex: 'createdDate',
    key: 'createdDate',
    width: '140px',
    render: (createdDate) =>
      new Date(createdDate).toLocaleString('vi-VN', {
        dateStyle: 'short',
        timeStyle: 'short',
      }),
  },
  {
    title: 'MST',
    dataIndex: 'mstSchool',
    key: 'mstSchool',
    width: '140px',
  },
  {
    title: 'Hành động',
    dataIndex: 'id',
    key: 'action',
    width: '140px',
    render: (id) => <Link to={`/bill-list`}>Chi tiết</Link>,
  },
];

export const CreateBillError = () => {
  const [billList, setBillList] = useState<BillErrorDataType[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const schoolId = localStorage.getItem('schoolId');

  useEffect(() => {
    const queryParams = new URLSearchParams({
      page: page.toString(),
      perPage: pageSize.toString(),
      schoolId: schoolId || '',
    });

    const url = `/cms/invoice/list-invoices-error?${queryParams.toString()}`;

    const fetchData = async () => {
      try {
        const response: ResponseDataType<ResponseInfoType> = await getRequest(url);
        if (response && response.code === 200) {
          setBillList(response.info?.list || []);
          setTotal(response.info?.total || 0);
        } else {
          console.log(response.msg);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [page, pageSize, schoolId]);

  const handleTableChange = (pagination: any) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={billList}
        rowKey="id"
        pagination={{
          current: page,
          pageSize: pageSize,
          total: total,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
        }}
        onChange={handleTableChange}
      />
    </div>
  );
};
